import React, { useState } from "react";
import "./Freight.css";
import Header from "../../Shared/Header/Header";
import IFTA from "../../Componenets/IFTA/IFTA";

const Freight = () => {
  const [skuValue, setSkuValue] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState("");
  const [listProducts, setListProducts] = useState([]);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [fromPostalCode, setFromPostalCode] = useState("83050-080");
  const [toPostalCode, setToPostalCode] = useState("");
  const [insuranceValue, setInsuranceValue] = useState("");
  const [freightResult, setFreightResult] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleProduct = async () => {
    setError("");

    if (!skuValue.trim() || !quantity || quantity <= 0) {
      setError("Por favor, insira um sku válido e uma quantidade válida.");
      return;
    }

    try {
      const searchResponse = await fetch(
        `${apiUrl}/ERP/Products/Search?skuValue=${encodeURIComponent(
          skuValue
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );

      if (!searchResponse.ok) {
        const errorData = await searchResponse.json();
        throw new Error(errorData.msg || "Erro ao buscar sku ");
      }

      const searchData = await searchResponse.json();
      if (!searchData.produtoId) {
        setError("Nenhum produto encontrado.");
        return;
      }

      const productResponse = await fetch(
        `${apiUrl}/ERP/Products/Obter?id=${encodeURIComponent(
          searchData.produtoId
        )}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );

      if (!productResponse.ok) {
        const errorData = await productResponse.json();
        throw new Error(errorData.msg || "Erro ao obter detalhes do produto");
      }

      const productData = await productResponse.json();
      const {
        peso_liquido,
        comprimentoEmbalagem,
        alturaEmbalagem,
        larguraEmbalagem,
      } = productData.retorno.produto;

      setListProducts((prevProducts) => {
        const existingProductIndex = prevProducts.findIndex(
          (prod) => prod.skuValue === skuValue
        );

        if (existingProductIndex !== -1) {
          const updatedProducts = prevProducts.map((prod, index) =>
            index === existingProductIndex
              ? {
                  ...prod,
                  quantity: prod.quantity + Number(quantity),
                }
              : prod
          );

          updateTotals(updatedProducts);
          return updatedProducts;
        } else {
          const newProduct = {
            id: searchData.produtoId,
            skuValue: skuValue,
            weight: Number(peso_liquido),
            length: Number(comprimentoEmbalagem),
            height: Number(alturaEmbalagem),
            width: Number(larguraEmbalagem),
            insuranceValue: Number(insuranceValue) || 0,
            quantity: Number(quantity),
          };

          const updatedList = [...prevProducts, newProduct];
          updateTotals(updatedList);
          return updatedList;
        }
      });

      setSkuValue("");
      setInsuranceValue("");
      setQuantity(1);
    } catch (err) {
      setError(err.message);
    }
  };

  const updateTotals = (productList) => {
    setTotalHeight(
      productList.reduce((sum, prod) => sum + prod.height, 0).toFixed(2)
    );
    setTotalWidth(
      productList.reduce((sum, prod) => sum + prod.width, 0).toFixed(2)
    );
    setTotalLength(
      productList.reduce((sum, prod) => sum + prod.length, 0).toFixed(2)
    );
    setTotalWeight(
      productList.reduce((sum, prod) => sum + prod.weight, 0).toFixed(2)
    );
  };

  const handleFreightCalculation = async () => {
    setError("");
    console.log("Altura total:" + totalHeight);
    console.log("Largura total:" + totalLength);
    console.log("Peso total:" + totalWeight);
    console.log("Comprimento total:" + totalWidth);

    if (
      !fromPostalCode.trim() ||
      !toPostalCode.trim() ||
      listProducts.length === 0
    ) {
      setError("Preencha os campos e adicione pelo menos um produto.");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/ERP/Frete/Calculadora?fromPostalCode=${encodeURIComponent(
          fromPostalCode
        )}&toPostalCode=${encodeURIComponent(
          toPostalCode
        )}&products=${encodeURIComponent(JSON.stringify(listProducts))}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );

      console.log("Lista de produtos abaixo");
      console.log(listProducts);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Erro ao calcular o frete");
      }

      const data = await response.json();

      if (data && Array.isArray(data.data)) {
        const filteredData = data.data.filter(
          (service) => service.name === "PAC" || service.name === "SEDEX"
        );
        setFreightResult(filteredData);
      } else {
        throw new Error("Dados do frete não encontrados.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClearData = () => {
    setSkuValue("");
    setQuantity(1);
    setError("");
    setListProducts([]);
    setTotalHeight(0);
    setTotalWidth(0);
    setTotalLength(0);
    setTotalWeight(0);
    setFromPostalCode("");
    setToPostalCode("");
    setInsuranceValue("");
    setFreightResult(null);
  };

  const handleClientMsg = () => {
    if (!freightResult || freightResult.length === 0) {
      setError("Nenhum resultado de frete disponível para copiar.");
      return;
    }

    let mensagem = "Valor do frete:\n";

    freightResult.forEach((option) => {
      const price = Number(option.price * 1.3);

      if (!isNaN(price)) {
        mensagem += `${option.name}: R$${price.toFixed(2)} - ${
          option.delivery_time
        } dias úteis\n`;
      } else {
        console.error(
          `Erro: option.price não é um número válido`,
          option.price
        );
      }
    });

    navigator.clipboard
      .writeText(mensagem)
      .then(() => alert("Mensagem copiada para a área de transferência!"))
      .catch(() => setError("Erro ao copiar mensagem."));
  };

  return (
    <div className="overflow">
      <Header />
      <div className="AppFreight">
        <h1 className="tittleFreight">Cotação para Frete</h1>
        <main className="calculatorBox">
          <div className="shippingBox">
            <h1 className="shipimentData">Dados de Envio</h1>
            <IFTA
              text="Cep de origem"
              value={fromPostalCode}
              onChange={(e) => setFromPostalCode(e.target.value)}
            />
            <IFTA
              text="Cep de destino"
              value={toPostalCode}
              onChange={(e) => setToPostalCode(e.target.value)}
              mask="99999-999"
            />

            <button
              className="calculatorButton"
              onClick={handleFreightCalculation}
            >
              Calcular frete
            </button>
            {listProducts.length > 0 && (
              <>
                <button className="calculatorButton" onClick={handleClearData}>
                  Limpar dados
                </button>

                <button className="calculatorButton" onClick={handleClientMsg}>
                  Copiar mensagem
                </button>
              </>
            )}
          </div>
          <div className="productsBox">
            <div className="productsDetails">
              <h1>Produtos</h1>
              <div className="productsInfo">
                <IFTA
                  text="SKU do produto"
                  value={skuValue}
                  onChange={(e) => setSkuValue(e.target.value)}
                />
                <IFTA
                  text="Valor do material"
                  value={insuranceValue}
                  onChange={(e) => setInsuranceValue(e.target.value)}
                />
                <IFTA
                  text="Quantidade"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <button className="productButton" onClick={handleProduct}>
                  Adicionar produto
                </button>
              </div>
            </div>

            {error && <p className="error">{error}</p>}

            <div className="productList">
              <h3>Lista de Produtos</h3>
              <div className="tableContainer">
                <table className="productTable">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Quantidade</th>
                      <th>Dimensões (Altura, Largura, Comprimento e Peso)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listProducts.map((product, index) => (
                      <tr key={index}>
                        <td>{product.skuValue}</td>
                        <td>{product.quantity}</td>
                        <td>
                          {product.height}cm x {product.length}cm x{" "}
                          {product.width}cm x {product.weight}kg
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {freightResult && (
              <div className="freightResult">
                <h3>Resultado do Frete</h3>
                {freightResult.length > 0 ? (
                  <table className="freightTable">
                    <thead>
                      <tr>
                        <th>Transportadora</th>
                        <th>Valor</th>
                        <th>Valor + 30%</th>
                        <th>Prazo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {freightResult.map((option, index) => (
                        <tr key={index}>
                          <td>{option.name}</td>
                          <td>R$ {option.price}</td>
                          <td>R$ {(option.price * 1.3).toFixed(2)}</td>
                          <td>{option.delivery_time} dias</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>Nenhuma opção de frete encontrada.</p>
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Freight;
