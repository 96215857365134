import React from "react";
import "./IFTA.css";
import InputMask from "react-input-mask";

const IFTA = ({ text, value, onChange, mask }) => {
  return (
    <div className="ifta">
      <InputMask
        className="iftaInput"
        type="text"
        mask={mask}
        placeholder={text}
        value={value}
        onChange={onChange}
      />
      <label className="iftaLabel" htmlFor={text}>
        {text}
      </label>
      <div className="inputShadow" />
    </div>
  );
};

export default IFTA;
