import logoBranca from "../../img/logos/logo-btk-white.svg";
import "./Header.css";

export default function Header() {
  const handleBtkLogo = () => {
    window.open("/", "_self");
  };
  return (
    <header className="header">
      <div className="navbar">
        <img
          src={logoBranca}
          className="Logo"
          alt="BTK SOLUTIONS"
          onClick={handleBtkLogo}
        />
      </div>
    </header>
  );
}
