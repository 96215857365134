import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProposalForm from "./Pages/ProposalForm/ProposalForm.js";
import Loading from "./Pages/Loading/Loading.js";
import CreatePDF from "./Pages/CreatePDF/CreatePDF.js";
import Home from "./Pages/Home/Home.js"
import Freight from "./Pages/Freight/Freight.js"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Cotação-Frete" element={<Freight />} />
        <Route path="/Proposta-Comercial" element={<ProposalForm />} />
        <Route path="Loading" element={<Loading />} />
        <Route path="/PDF-Proposta-Comercial" element={<CreatePDF />} />
      </Routes>
    </Router>
  );
}

export default App;
