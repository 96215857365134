import React from "react";
import Header from "../../Shared/Header/Header";
import "./Home.css";

const Home = () => {
  const handleSubmitProposal = () => {
    window.open("/Proposta-Comercial", "_self");
  };
  const handleSubmitFreight = () => {
    window.open("/Cotação-Frete", "_self");
  };

  return (
    <div className="App">
      <Header />
      <main className="home">
        <h1>Acesse a página</h1>
        <div className="buttonsHome">
          <button onClick={handleSubmitProposal}>Proposta Comercial</button>
          <button onClick={handleSubmitFreight}>Cotação Frete</button>
        </div>
      </main>
    </div>
  );
};

export default Home;
