import React, { useState, useEffect } from "react";
import "./Loading.css";
import { ExtractData } from "../ProposalForm/ExtractingData";
import Sergio from "../../img/sergioCorrendo.gif";

export default function Loading({ link }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const proposalLink = sessionStorage.getItem("proposalLink");

    if (proposalLink) {
      ExtractData(proposalLink).then(() => {
        const storedData = sessionStorage.getItem("proposalData");
        if (storedData) {
          sessionStorage.setItem("proposalData", storedData);
        } else {
          console.error("Nenhum dado encontrado para a proposta.");
        }
      });
    } else {
      console.error("Nenhum link encontrado no sessionStorage.");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          window.open("/PDF-Proposta-Comercial", "_self");
          return 100;
        }
        return prev + 1;
      });
    }, 30);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loadingPage">
      <h1 className="title">
        Sérgio está preparando uma proposta quentinha...
      </h1>
      <img className="gif" src={Sergio} alt="Sérgio correndo com a proposta" />
      <div className="progressContainer">
        <div className="progressBar" style={{ width: `${progress}%` }}></div>
      </div>
      <p className="progressText">{progress}%</p>
    </div>
  );
}
